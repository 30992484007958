//$base-path: '../';

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower
@import "hamburgers/hamburgers.scss";
@import "_config.scss";

$main: #fff;
$bg-light: #e5f7fc;
$bg: #cfedf5;
$bg-superlight: #f2fdff;
$facebook: #3969cf;
$twitter: #00a6e7;

@font-face {
    font-family: 'After Night';
    src: url('../fonts/AfterNight.woff2') format('woff2'),
        url('../fonts/AfterNight.woff') format('woff'),
        url('../fonts/AfterNight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@keyframes breath {
  0%   { transform: scale(1,1); }
  50% { transform: scale(1.05,1.05); }
  100% { transform: scale(1,1); }
}

@keyframes breath-first {
  0%   { opacity: 0;transform: scale(1,1);}
  10%   { opacity: 1; transform:scale(1.02,1.02);}
  20%   { opacity: 1; transform:scale(1.04,1.04);}
  30% { opacity: 0; transform:scale(1.06,1.06);}
  40% { opacity: 0; transform:scale(1.08,1.08);}
  50% { opacity: 1; transform:scale(1.1,1.1);}
  60%   { opacity: 1; transform:scale(1.08,1.08);}
  70%   { opacity: 0; transform:scale(1.06,1.06);}
  80%   { opacity: 1; transform:scale(1.04,1.04);}
  90% { opacity: 1;transform: scale(1.02,1.02);}
  100% { opacity: 0; transform:scale(1,1);}
}
@keyframes breath-second {
  0%   { opacity: 1; transform:scale(1,1);}
  10%   { opacity: 0; transform:scale(1.02,1.02);}
  20%   { opacity: 1; transform:scale(1.04,1.04);}
  30% { opacity: 1;transform: scale(1.06,1.06);}
  40% { opacity: 1;transform: scale(1.08,1.08);}
  50% { opacity: 0;transform: scale(1.1,1.1);}
  60%   { opacity: 1;transform: scale(1.08,1.08);}
  70%   { opacity: 1;transform: scale(1.06,1.06);}
  80%   { opacity: 0;transform: scale(1.04,1.04);}
  90% { opacity: 1;transform: scale(1.02,1.02);}
  100% { opacity: 1;transform: scale(1,1);}
}
@keyframes breath-third {
  0%   { opacity: 0; transform:scale(1,1);}
  10%   { opacity: 0;transform: scale(1.02,1.02);}
  20%   { opacity: 0;transform: scale(1.04,1.04);}
  30% { opacity: 0;transform: scale(1.06,1.06);}
  40% { opacity: 1;transform: scale(1.08,1.08);}
  50% { opacity: 1;transform: scale(1.1,1.1);}
  60%   { opacity: 0;transform: scale(1.08,1.08);}
  70%   { opacity: 1;transform: scale(1.06,1.06);}
  80%   { opacity: 1;transform: scale(1.04,1.04);}
  90% { opacity: 0;transform: scale(1.02,1.02);}
  100% { opacity: 0;transform: scale(1,1);}
}
@keyframes breath-reverse {
  0%   { transform: scale(1.05,1.05) ; }
  100% { transform: scale(1,1) ; }
}
@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, -10px); }
    to   { transform: translate(0, -0px); }    
}
@keyframes floating-side {
    from { transform: translate(0px,  0); }
    65%  { transform: translate(15px, 0); }
    to   { transform: translate(-0px, 0); }    
}


html{
  height: 100%;
  box-sizing: border-box;
  margin:0;
  padding:0;
}

body {
  overflow-x:hidden; 
  margin:0;
  padding:0;
  color:#fff;
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  width:100%;
  min-height: 100%;
  font-family: 'Raleway', serif;

 
}

.mouse-effect {
  z-index: 2;
  position:fixed;
  top:0;
  left: 0;
  transform: translate(-50%, -50%);
  transition: .2s;
  width: 500px;
  height: 500px;
  pointer-events: none;
  opacity: 0.5;
  background-position:center;
  background-size:contain;
  background-repeat:no-repeat;
  background-image:url(../images/mouse-effect.png);
}

.modal{

  .btn{
    background-color:transparent !important;
    border-radius:6px;
    border:3px solid #fff;
    span{
      color: #fff !important;
    }
  }

  .btn.logout{
    span{
      color: #fff !important;
      text-decoration: underline;
    }
  }
}

.modal-backdrop.in{
  opacity: 0.5 !important;
}

.cropper-line, .cropper-point {
  display: none !important;
}

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}

.page-wrapper {
  position: relative;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

a {
  color:$main !important;
  text-decoration: none !important;
}

.container{
  position: relative;
  max-width: 1100px !important;
  width: 100% !important;
}


button.cta {
  background-color: #ee5468;
  border-radius: 40px;
  border: none;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
  font-size: 12pt;
  font-weight: 500;
  color: #fff;
  margin-top: 15px;
  transition:opacity .4s ease;
  outline: none !important;
}

button.cta.disabled {
  background-color: #ccc;
}

button.cta.inverted {
  background-color: #fff;
  color: $main;
  border: 4px solid $main;
}

button.cta.inverted:hover {
  color: #fff;
  background-color: $main;
}

button {
  transition:opacity .4s ease;
}

button.cta.larger {
  padding-left: 30px;
  padding-right: 30px;
}

button:hover, button:active, button.active {
  opacity: 0.8;
}

button:active {
  opacity: 0.6;
}


.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-top:4px;
  left: 0;
  height: 70px;
  bottom:0px;
  background-color: rgba(0,0,0,0);

}

.page-wrapper{
  position: relative;
  height: 100%;
  margin-bottom: -25px;
  @media screen and (max-width : 767px) {
    margin-bottom: 0px;
  }
}
.cntngnc {
  height: 100%;
  padding-bottom: 0;

  position: relative;
}

.younity-container {
  height: 100%;
  width: 100%;
  position:relative;
  background:#e8e8e8;
  position:fixed;
  left: 0;
  right: 0;

  .side {
    position:relative;
    float: left;
    width: 50%;
    top: 0;
    height: 100%;

    @media screen and (max-width : 1200px) {
      width: 100% !important;
      height: 50%;
    }

    .bg {
      position:absolute;
      left: 0;
      right: 0;
      top:0;
      bottom:0;
      background-repeat:no-repeat;
      background-position:50% 0;
      background-size:contain;
    }
  }

  .side.left {
    left: 5%;
    z-index: 100;

    .button-container {
      position:absolute;
      left: 50%;
      margin-left: -85px;
      top: 480px;
      padding-top:0%;

      @media screen and (max-width : 1200px) {
        padding-top:0%;
        top: 280px;
      }

      button {
        font-family: sans-serif;
        width: 160px;
        padding-top:5px;
        padding-bottom:5px;
        background-color: transparent;
        color: #6fae57;
        border:2px solid #6fae57;
        font-size: 10pt;

      }
    }

    .bg {
      top: 100px;
      background-image:url(../images/left-younity.png);
      background-size: 480px auto;

      @media screen and (max-width : 1200px) {
        top: 20px;
        bottom:10px;
        background-size: 320px auto;
      }
    }
  }
  .side.right {
    right: 0;
    float: right;

    .bg {
      background-position:50% 100%;
      background-size:contain;
      background-image:url(../images/right-younity.jpg);
      @media screen and (max-width : 1200px) {
        background-position:100% 100%;
        background-size:contain;
      }

      @media screen and (min-width : 1000px) {
        background-position:100% 0%;
        background-size:50% auto;
        //top: 50%;
      }

      @media screen and (min-width : 1200px) {
        background-position:100% 100%;
        background-size:contain;
      }
      

    }
  }

  .side.short {
    width: 34%;
  }
  .side.right {
    width: 60%;
  }
}

.footer {
  .text {
    position:absolute;
    left: 20px;
    top: 5px;
    width: 100%;
    @media screen and (max-width : 700px) {
      width: 70%;
      top: 30px;
    }
    .text-image{
      float: left;
      width: 100%;
      height: 30px;
      background-color:transparent;
      background-size:contain;
      background-position: 0 100%;
      background-repeat:no-repeat;
      background-image:url(../images/footer.png);
    }
  }
  .socials {
    top: 0;
    position:absolute;
    right: 20px;

    .social {
      float: left;
      width: 40px;
      height: 40px;
      background-color:transparent;
      background-size:contain;
      background-position: center;
      background-repeat:no-repeat;
    }

    .social.fb {
      background-image:url(../images/icon-fb.png);
    }

    .social.ig {
      background-image:url(../images/icon-ig.png);
    }

    .social.wh {
      background-image:url(../images/icon-wh.png);
    }

    .social.ln {
      background-image:url(../images/icon-ln.png);
    }
  }
}
